import { RESTClient } from './client';
import { Invitation, InvitationDraft } from '../models/invitation';

export class InvitationClient extends RESTClient<Invitation> {
  public constructor(token?: string) {
    super('invitations', token);
  }

  public createMultiple(entities: InvitationDraft[], skipDuplicateValidation?: boolean) {
    // todo add type once redux is gone
    return this.clientInstance.post<any>(this.path, entities, {
      params: { skip_duplicate_validation: skipDuplicateValidation }
    });
  }

  public reinvite(id: number) {
    return this.clientInstance.post<Invitation>(`${this.path}/${id}/reinvite`);
  }

  public cancel(id: number) {
    return this.clientInstance.put<Invitation>(`${this.path}/${id}/cancel`);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public delete(_entity: Invitation): never {
    throw new Error('Not supported by API');
  }
}
