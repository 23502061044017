import { Spin } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { CertificationClient } from '../../client/certification';
import { useApiClient } from '../../hooks/client';
import { useIndexQuery } from '../../hooks/rootQueries';
import { Certification } from '../../models/certification';
import { Query } from '../../models/query';
import { QueryParams } from '../../models/queryParams';
import { resolveIdValues, resolveSelectedValue, SelectorProps } from '../../services/selectorService';
import { DebounceSelect } from './DebounceSelect';

type Props = {} & SelectorProps<Certification>;

export const CertificationSelector: React.FC<Props> = ({ value, onChange, selectProps }) => {
  const { formatMessage } = useIntl();

  const [queryParams, setQueryParams] = React.useState<QueryParams>({ page: 1 });

  const client = useApiClient(CertificationClient);
  const { data: certificationIndex, isLoading } = useIndexQuery(Query.CERTIFICATIONS, client, queryParams);

  const handleSearch = (value: string) => {
    setQueryParams({ ...queryParams, search: value });
  };

  const handleChange = (value: number | number[]) => {
    onChange(resolveSelectedValue(value, certificationIndex.data));
  };

  return (
    <DebounceSelect
      placeholder={formatMessage({ id: 'model.certification' })}
      onDebounceSearch={handleSearch}
      onChange={handleChange}
      value={resolveIdValues(value)}
      options={certificationIndex.data.map((c) => ({ value: c.id, label: c.title }))}
      notFoundContent={isLoading && <Spin size="small" />}
      {...selectProps}
    />
  );
};
