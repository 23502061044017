import * as React from 'react';
import styled from '@emotion/styled';
import { Input, Form, Button, Select, InputNumber } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { createRequiredRule } from '../../../config/validation';

const ChoiceContainer = styled.div`
  display: flex;
  margin-bottom: 12px;

  .ant-row {
    flex-grow: 1;
  }

  .ant-btn {
    margin-left: 12px;
  }
`;

interface Props {
  form: FormInstance;
}

export const SingleChoiceItemForm: React.FC<Props> = (props) => {
  const intl = useIntl();
  const requiredRule = createRequiredRule(intl);
  const [renderCount, setRenderCount] = React.useState(0);

  const forceRender = () => {
    setRenderCount(renderCount + 1);
  };

  const getChoice = () => (props.form.getFieldValue(['definition', 'choice']) as string[]) || [];
  const getSolution = () => (props.form.getFieldValue(['definition', 'solution']) as string) || '';

  const createHandleChoiceChange = (lastValue: string) => {
    return (event: React.FocusEvent<HTMLInputElement>) => {
      if (getSolution() === lastValue) {
        props.form.setFieldsValue({ definition: { solution: event.target.value } });
      }
      forceRender();
    };
  };

  return (
    <>
      <Form.Item name={['definition', 'question']} label={intl.formatMessage({ id: 'model.attribute.question' })} rules={[requiredRule]}>
        <Input />
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: 'model.attribute.choice' })} required>
        <Form.List name={['definition', 'choice']}>
          {(choices, { add, remove }) => (
            <>
              {choices.map((choice) => (
                <ChoiceContainer key={choice.key}>
                  <Form.Item name={choice.name} rules={[requiredRule]}>
                    <Input onBlur={createHandleChoiceChange(props.form.getFieldValue(['definition', 'choice', choice.name]))} />
                  </Form.Item>
                  {getSolution().indexOf(props.form.getFieldValue(['definition', 'choice', choice.name])) === -1 ? (
                    <Button type="dashed" onClick={() => remove(choice.name)}>
                      <MinusOutlined />
                    </Button>
                  ) : null}
                </ChoiceContainer>
              ))}
              <Button type="dashed" onClick={() => add()}>
                <PlusOutlined />
                <FormattedMessage id="action.add" values={{ entity: intl.formatMessage({ id: 'model.attribute.choice' }) }} />
              </Button>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item name={['definition', 'solution']} label={intl.formatMessage({ id: 'model.attribute.solution' })} rules={[requiredRule]}>
        <Select<string[]> onChange={forceRender}>
          {getChoice()
            .filter((c) => c !== '')
            .map((c) => (
              <Select.Option value={c} key={c}>
                {c}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item name={['definition', 'points']} label={intl.formatMessage({ id: 'model.attribute.points' })} rules={[requiredRule]}>
        <InputNumber min={0} />
      </Form.Item>
    </>
  );
};
