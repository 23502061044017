import { Spin } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { ParticipantClient } from '../../client/participant';
import { useApiClient } from '../../hooks/client';
import { useIndexQuery } from '../../hooks/rootQueries';
import { Participant } from '../../models/participant';
import { Query } from '../../models/query';
import { QueryParams } from '../../models/queryParams';
import { resolveIdValues, resolveSelectedValue, SelectorProps } from '../../services/selectorService';
import { DebounceSelect } from './DebounceSelect';

type Props = {
  activeParticipantsOnly?: boolean;
} & SelectorProps<Participant>;

export const ParticipantSelector: React.FC<Props> = ({ onChange, value, selectProps, activeParticipantsOnly }) => {
  const { formatMessage } = useIntl();

  const [queryParams, setQueryParams] = React.useState<QueryParams>({ page: 1, filters: activeParticipantsOnly && { active: 'true', company_active: 'true' } });

  const client = useApiClient(ParticipantClient);
  const { data: participantIndex, isLoading } = useIndexQuery(Query.PARTICIPANTS, client, queryParams);

  const handleSearch = (value: string) => {
    setQueryParams({ ...queryParams, search: value });
  };

  const handleChange = (value: number | number[]) => {
    onChange(resolveSelectedValue(value, participantIndex.data));
  };

  return (
    <DebounceSelect
      placeholder={formatMessage({ id: 'model.participant' })}
      onDebounceSearch={handleSearch}
      onChange={handleChange}
      value={resolveIdValues(value)}
      options={participantIndex.data.map((p) => ({ value: p.id, label: `${p.forename} ${p.surname} (${p.company.name})` }))}
      notFoundContent={isLoading && <Spin size="small" />}
      {...selectProps}
    />
  );
};
