import * as React from 'react';
import { Button, Space, Table, TableColumnsType, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { Invitation } from '../../models/invitation';
import { CheckOutlined, ReloadOutlined, StopOutlined } from '@ant-design/icons';
import { paginationLayoutConfig } from '../../config/pagination';
import {
  getCertificationFilters,
  getYesNoFilters,
  useDynamicTable,
  IndexProps,
  renderDateTimeCell,
  renderFullNameCell,
  getCancelledRowComponentProps,
  renderStatusCell,
  getFiltersFromEnum
} from '../../services/indexService';
import { Certification } from '../../models/certification';
import { MultiSelectFilter } from '../MultiSelectFilter';
import { ParticipantSelector } from '../selectors/ParticipantSelector';
import { isExpired, isOpen, ResultStatus } from '../../models/result';

interface Props extends IndexProps<Invitation> {
  certifications: Certification[];
  onReinvite: (id: number) => void;
  onCancel: (id: number) => void;
}

export const InvitationIndex: React.FC<Props> = ({ certifications, onReinvite, onCancel, index }) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const { handleTableChanges, getFilterColumnProps, getSortColumnProps } = useDynamicTable();

  const columns: TableColumnsType<Invitation> = [
    {
      title: formatMessage({ id: 'model.id' }),
      dataIndex: 'id',
      width: 80,
      ...getSortColumnProps('invitations.id')
    },
    {
      title: formatMessage({ id: 'model.participant' }),
      dataIndex: 'participant',
      render: (_text, record: Invitation) => renderFullNameCell(record.participant),
      ...getFilterColumnProps('participant', (props) => <MultiSelectFilter Selector={ParticipantSelector} filterDropdownProps={props} />)
    },
    {
      title: formatMessage({ id: 'model.certification' }),
      dataIndex: ['certification', 'title'],
      ...getFilterColumnProps('certification', getCertificationFilters(certifications))
    },
    {
      title: formatMessage({ id: 'model.attribute.status' }),
      dataIndex: 'status',
      render: renderStatusCell(formatMessage),
      ...getFilterColumnProps('status', getFiltersFromEnum(ResultStatus, 'status', intl))
    },
    {
      title: formatMessage({ id: 'model.attribute.validUntil' }),
      dataIndex: 'validUntil',
      render: (text) => renderDateTimeCell(text, intl),
      ...getSortColumnProps('valid_until')
    },
    {
      title: formatMessage({ id: 'model.attribute.createdAt' }),
      dataIndex: 'createdAt',
      render: (text) => renderDateTimeCell(text, intl),
      ...getSortColumnProps('invitations.created_at')
    },
    {
      title: formatMessage({ id: 'model.attribute.reinvite' }),
      dataIndex: 'reinvite',
      render: (value: boolean) => (value ? <CheckOutlined /> : <StopOutlined />),
      ...getFilterColumnProps('reinvite', getYesNoFilters(intl))
    },
    {
      title: formatMessage({ id: 'actions' }),
      align: 'center',
      render: (_value, record) =>
        !record.cancelledAt && (
          <Space.Compact>
            {isExpired(ResultStatus[record.status]) && (
              <Tooltip title={formatMessage({ id: 'action.manuallyReinvite' })}>
                <Button onClick={() => onReinvite(record.id)} icon={<ReloadOutlined />} />
              </Tooltip>
            )}
            {isOpen(record.status) && (
              <Tooltip title={formatMessage({ id: 'action.cancelInvitation' })}>
                <Button onClick={() => onCancel(record.id)} icon={<StopOutlined />} />
              </Tooltip>
            )}
          </Space.Compact>
        )
    }
  ];

  return (
    <Table<Invitation>
      dataSource={index.data}
      columns={columns}
      pagination={{ ...index.meta, ...paginationLayoutConfig }}
      onChange={handleTableChanges}
      rowKey="id"
      onRow={getCancelledRowComponentProps(intl)}
    />
  );
};
