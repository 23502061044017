import { Spin } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { CountryClient } from '../../client/country';
import { useApiClient } from '../../hooks/client';
import { useIndexQuery } from '../../hooks/rootQueries';
import { Country } from '../../models/country';
import { Query } from '../../models/query';
import { QueryParams } from '../../models/queryParams';
import { resolveIdValues, resolveSelectedValue, SelectorProps } from '../../services/selectorService';
import { DebounceSelect } from './DebounceSelect';

type Props = {} & SelectorProps<Country>;

export const CountrySelector: React.FC<Props> = ({ onChange, value, selectProps }) => {
  const { formatMessage } = useIntl();

  const initialParams: QueryParams = { page: 1 };
  const [queryParams, setQueryParams] = React.useState(initialParams);

  const client = useApiClient(CountryClient);
  const { data: countryIndex, isLoading } = useIndexQuery(Query.COUNTRIES, client, queryParams);

  const handleSearch = (value: string) => {
    setQueryParams({ ...queryParams, search: value });
  };

  const handleChange = (value: number | number[]) => {
    onChange(resolveSelectedValue(value, countryIndex.data));
  };

  return (
    <DebounceSelect
      placeholder={formatMessage({ id: 'model.country' })}
      onDebounceSearch={handleSearch}
      value={resolveIdValues(value)}
      onChange={handleChange}
      options={countryIndex.data.map((c) => ({ value: c.id, label: c.name }))}
      notFoundContent={isLoading && <Spin size="small" />}
      {...selectProps}
    />
  );
};
