import { Participant, ParticipantDraft } from '../../models/participant';
import * as React from 'react';
import { Button, Modal } from 'antd';
import { useIntl } from 'react-intl';
import { Company } from '../../models/company';
import { Gender } from '../../models/gender';
import { ImportOutlined } from '@ant-design/icons';
import { ColumnType, ValidationError } from 'react-dsv-import';
import { DSVImport } from '../DSVImport';

interface Props {
  companies: Company[];
  genders: Gender[];
  participants: Participant[];
  saveParticipants: (participants: Participant[] | ParticipantDraft[]) => void;
}

type ImportType = { gender: string; forename: string; surname: string; email: string; company: string };

const ParticipantsImporter: React.FC<Props> = (props) => {
  const intl = useIntl();
  const [open, isOpen] = React.useState(false);
  const [valid, isValid] = React.useState(false);
  const participants = React.useRef<ParticipantDraft[]>([]);

  const toggleModal = () => {
    isOpen(!open);
  };

  const handleSubmit = () => {
    props.saveParticipants(participants.current.map((p) => ({ ...p, copyInvitationsToCompany: true })));
    toggleModal();
  };

  const handleImportChange = (data: { [key: string]: string }[]) => {
    const processedData: ParticipantDraft[] = data.map(
      ({ forename, surname, email, company, gender }) =>
        ({
          forename,
          surname,
          email,
          companyId: props.companies.find((c) => c.name === company)?.id,
          genderId: props.genders.find((g) => g.name === gender.toLowerCase())?.id
        } as ParticipantDraft)
    );
    participants.current = processedData;
  };

  const handleValidation = (errors: ValidationError<ImportType>[]) => {
    isValid(errors.length === 0 && participants.current.length > 0);
  };

  const genders = props.genders.map((g) => g.name.toLowerCase());
  const validateGender = (value: string) => !genders.includes(value.toLowerCase()) && value !== '';
  const companies = props.companies.map((c) => c.name);
  const validateCompanies = (value: string) => !companies.includes(value);
  const emails = props.participants.map((p) => p.email);
  const validateEmails = (value: string) => emails.includes(value);
  const columns: ColumnType<ImportType>[] = [
    {
      key: 'gender',
      label: intl.formatMessage({ id: 'model.gender' }),
      rules: [
        {
          message: intl.formatMessage({ id: 'validation.valueRange' }, { values: genders.join(', ') }),
          constraint: { callback: validateGender }
        }
      ]
    },
    { key: 'forename', label: intl.formatMessage({ id: 'model.attribute.forename' }) },
    { key: 'surname', label: intl.formatMessage({ id: 'model.attribute.surname' }) },
    {
      key: 'email',
      label: intl.formatMessage({ id: 'model.attribute.email' }),
      rules: [
        { message: intl.formatMessage({ id: 'validation.unique' }), constraint: { unique: true } },
        {
          message: intl.formatMessage({ id: 'validation.used' }, { entity: intl.formatMessage({ id: 'model.attribute.email' }) }),
          constraint: { callback: validateEmails }
        }
      ]
    },
    {
      key: 'company',
      label: intl.formatMessage({ id: 'model.company' }),
      rules: [
        {
          message: intl.formatMessage({ id: 'validation.existence' }, { model: intl.formatMessage({ id: 'model.company' }) }),
          constraint: { callback: validateCompanies }
        }
      ]
    }
  ];

  return (
    <>
      <Button icon={<ImportOutlined />} onClick={toggleModal}>
        {intl.formatMessage({ id: 'action.import' }, { entity: intl.formatMessage({ id: 'model.participants' }) })}
      </Button>
      <Modal
        title={intl.formatMessage({ id: 'action.import' }, { entity: intl.formatMessage({ id: 'model.participants' }) })}
        open={open}
        onOk={handleSubmit}
        onCancel={toggleModal}
        maskClosable={false}
        destroyOnClose={true}
        okButtonProps={{ disabled: !valid }}
        width="80%"
      >
        <DSVImport<ImportType> columns={columns} onChange={handleImportChange} onValidation={handleValidation} />
      </Modal>
    </>
  );
};

export default ParticipantsImporter;
