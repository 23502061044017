import * as React from 'react';
import { Table, Select, Form, Checkbox, Input, Divider } from 'antd';
import { useIntl } from 'react-intl';
import { InvitationDraft } from '../../models/invitation';
import { Participant } from '../../models/participant';
import { Certification } from '../../models/certification';
import { FilterDropdownProps, TableRowSelection } from 'antd/lib/table/interface';
import { CheckOutlined } from '@ant-design/icons';
import { FormInstance, Rule } from 'antd/lib/form';
import { createMinDateRule, createRepresentativeValidationRule, createRequiredRule, createUniqueRule } from '../../config/validation';
import { User } from '../../models/user';
import { CertificationSelector } from '../selectors/CertificationSelector';
import { useApiClient } from '../../hooks/client';
import { DefaultValueClient } from '../../client/defaultValue';
import { useDefaultValues } from '../../hooks/defaultValues';
import { useIndexQuery } from '../../hooks/rootQueries';
import { ParticipantClient } from '../../client/participant';
import { Query } from '../../models/query';
import { useDynamicTable } from '../../services/indexService';
import { QueryParams } from '../../models/queryParams';
import { MultiSelectFilter } from '../MultiSelectFilter';
import { CompanySelector } from '../selectors/CompanySelector';
import { DebounceSearch } from '../DebounceSearch';
import { paginationLayoutConfig } from '../../config/pagination';
import styled from '@emotion/styled';
import { DatePicker } from '../DatePicker';
import { addDays, addWeeks, endOfDay } from 'date-fns';

const StyledSearchField = styled(DebounceSearch)`
  width: 20rem;
  margin-bottom: 1rem;
`;

interface FormModel {
  participants: number;
  certification: Certification;
  customInviteNotificationTemplate?: string;
  temporarySecondaryRepresentativeId?: number;
  validUntil: Date;
  reinvite: boolean;
}

interface Props {
  users: User[];
  form: FormInstance<FormModel>;
  onInvite: (invitations: InvitationDraft[]) => void;
}

export const InvitationForm: React.FC<Props> = (props) => {
  const intl = useIntl();
  const [rowSelection, setRowSelection] = React.useState<number[]>([]);

  const [queryParams, setQueryParams] = React.useState<QueryParams>({ filters: { active: 'true', company_active: 'true' }, page: 1 });

  const { handleTableChanges, getFilterColumnProps, getSortColumnProps } = useDynamicTable(queryParams, setQueryParams);

  const participantClient = useApiClient(ParticipantClient);
  const { data: participantIndex, isLoading: participantsLoading } = useIndexQuery(Query.PARTICIPANTS, participantClient, queryParams);

  const client = useApiClient(DefaultValueClient);
  const { data: defaultValues } = useDefaultValues(client);

  const initialValues: Partial<FormModel> = React.useMemo(
    () => ({
      validUntil: addDays(new Date(), defaultValues['invitation.validUntil'] as number),
      reinvite: true
    }),
    [defaultValues]
  );

  React.useEffect(() => {
    if (!props.form.isFieldsTouched(Object.keys(initialValues))) {
      props.form.setFieldsValue(initialValues);
    }
  }, [defaultValues, initialValues, props.form]);

  const requiredRule = createRequiredRule(intl);
  const representativeValidationRule = createRepresentativeValidationRule(props.users, intl.formatMessage);
  const createUniqueRepresentativeValidationRule = (certification: Certification) =>
    createUniqueRule(intl, [certification?.primaryRepresentative.id], intl.formatMessage({ id: 'validation.sameAsPrimaryRepresentative' }));

  const handleChange = ({ certification }: Partial<FormModel>) => {
    if (certification) {
      props.form.setFieldsValue({ customInviteNotificationTemplate: certification.inviteNotificationTemplate?.message ?? '' });
      props.form.validateFields(['temporarySecondaryRepresentativeId']);
    }
  };

  const handleSubmit = (values: FormModel) => {
    const newInvitations: InvitationDraft[] = rowSelection.map((row) => ({
      participantId: row,
      certificationId: values.certification.id,
      customInviteNotificationTemplate: values.customInviteNotificationTemplate,
      temporarySecondaryRepresentativeId: values.temporarySecondaryRepresentativeId,
      validUntil: values.validUntil.toISOString(),
      reinvite: values.reinvite
    }));
    props.onInvite(newInvitations);
  };

  const onSelectionChange = (ids: number[]) => {
    setRowSelection(ids);
  };

  const rowSelectionConfig: TableRowSelection<Participant> = {
    selectedRowKeys: rowSelection,
    onChange: onSelectionChange,
    preserveSelectedRowKeys: true
  };

  const renderCopyInvitationsToCompany = (value: boolean, record: Participant) => value && record.company.administrativeEmail && <CheckOutlined />;

  const validateSelectedParticipants = (_rule: Rule, _value: string, callback: (error?: string) => void) => {
    if (rowSelection.length <= 0) {
      callback('No rows selected.');
    } else {
      callback();
    }
  };

  const CompanyMultiSelect = (props: FilterDropdownProps) => <MultiSelectFilter Selector={CompanySelector} filterDropdownProps={props} />;

  return (
    <Form name="invitationForm" form={props.form} onValuesChange={handleChange} onFinish={handleSubmit} layout="vertical" initialValues={initialValues}>
      <Form.Item name="certification" rules={[requiredRule]} label={intl.formatMessage({ id: 'model.certification' })}>
        <CertificationSelector />
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.certification?.id !== currentValues.certification?.id}>
        {({ getFieldsValue }) => (
          <>
            <Form.Item name="customInviteNotificationTemplate" label={intl.formatMessage({ id: 'model.attribute.customInviteNotificationTemplate' })}>
              <Input.TextArea rows={3} disabled={!getFieldsValue().certification} />
            </Form.Item>
            <Form.Item
              name="temporarySecondaryRepresentativeId"
              rules={[representativeValidationRule, createUniqueRepresentativeValidationRule(getFieldsValue().certification)]}
              label={intl.formatMessage({ id: 'model.attribute.temporarySecondaryRepresentative' })}
            >
              <Select placeholder={intl.formatMessage({ id: 'model.attribute.temporarySecondaryRepresentative' })} allowClear>
                {props.users.map((c) => (
                  <Select.Option value={c.id} key={c.id}>
                    {`${c.forename} ${c.surname}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}
      </Form.Item>
      <Divider>{intl.formatMessage({ id: 'model.participants' })}</Divider>
      <StyledSearchField onDebounceSearch={(value) => setQueryParams({ ...queryParams, search: value })} allowClear loading={participantsLoading} />
      <Form.Item name="requiredParticipants" rules={[{ ...requiredRule, validator: validateSelectedParticipants }]}>
        <Table
          rowSelection={rowSelectionConfig}
          dataSource={participantIndex.data}
          rowKey="id"
          onChange={handleTableChanges}
          loading={participantsLoading}
          pagination={{ ...paginationLayoutConfig, ...participantIndex.meta }}
        >
          <Table.Column title={intl.formatMessage({ id: 'model.id' })} dataIndex="id" {...getSortColumnProps('participants.id')} />
          <Table.Column title={intl.formatMessage({ id: 'model.attribute.email' })} dataIndex="email" />
          <Table.Column title={intl.formatMessage({ id: 'model.attribute.forename' })} dataIndex="forename" key="forename" />
          <Table.Column title={intl.formatMessage({ id: 'model.attribute.surname' })} dataIndex="surname" key="surname" />
          <Table.Column title={intl.formatMessage({ id: 'model.company' })} dataIndex={['company', 'name']} {...getFilterColumnProps('company', CompanyMultiSelect)} />
          <Table.Column
            title={intl.formatMessage({ id: 'model.attribute.copyInvitationsToCompany' })}
            dataIndex="copyInvitationsToCompany"
            key="copyInvitationsToCompany"
            render={renderCopyInvitationsToCompany}
          />
        </Table>
      </Form.Item>
      <Form.Item
        name="validUntil"
        rules={[requiredRule, createMinDateRule(intl.formatMessage({ id: 'validation.minInvitationValidity' }), 6)]}
        label={intl.formatMessage({ id: 'model.attribute.validUntil' })}
      >
        <DatePicker style={{ width: '100%' }} disabledDate={(current) => current < endOfDay(addWeeks(new Date(), 1))} />
      </Form.Item>
      <Form.Item name="reinvite" valuePropName="checked" label={intl.formatMessage({ id: 'model.attribute.reinvite' })}>
        <Checkbox />
      </Form.Item>
    </Form>
  );
};
