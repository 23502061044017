import { RESTClient } from './client';
import { Country } from '../models/country';

export class CountryClient extends RESTClient<Country> {
  public constructor(token?: string) {
    super('countries', token);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public read(_entity: Country): never {
    throw new Error('Not supported by API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public create(_entity: Country): never {
    throw new Error('Not supported by API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public update(_entity: Country): never {
    throw new Error('Not supported by API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public delete(_entity: Country): never {
    throw new Error('Not supported by API');
  }
}
