import * as React from 'react';
import { Table, Button, Tooltip, Popconfirm, Space } from 'antd';
import { useIntl } from 'react-intl';
import { Bill } from '../../models/bill';
import { BillForm } from './BillForm';
import { DeleteOutlined, FilePdfOutlined } from '@ant-design/icons';
import { paginationLayoutConfig } from '../../config/pagination';
import { useDynamicTable, IndexProps, renderDateTimeCell } from '../../services/indexService';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { MultiSelectFilter } from '../MultiSelectFilter';
import { CompanySelector } from '../selectors/CompanySelector';

interface Props extends IndexProps<Bill> {
  updateBill: (bill: Bill) => void;
  deleteBill: (bill: Bill) => void;
}

export const BillIndex: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const { handleTableChanges, getFilterColumnProps, getSortColumnProps } = useDynamicTable();

  const renderActions = (record: Bill) => (
    <Space.Compact>
      <BillForm bill={record} saveBill={props.updateBill} />
      <Popconfirm title={formatMessage({ id: 'action.confirm.deletion' })} onConfirm={() => props.deleteBill(record)}>
        <Button icon={<DeleteOutlined />} />
      </Popconfirm>
      {record.billPdfUrl && (
        <Tooltip title={formatMessage({ id: 'action.show' }, { entity: formatMessage({ id: 'model.bill' }) })}>
          <a href={record.billPdfUrl} target="_blank" rel="noreferrer">
            <Button icon={<FilePdfOutlined />} />
          </a>
        </Tooltip>
      )}
    </Space.Compact>
  );

  const CompanyMultiSelect = (props: FilterDropdownProps) => <MultiSelectFilter Selector={CompanySelector} filterDropdownProps={props} />;

  return (
    <Table dataSource={props.index.data} pagination={{ ...props.index.meta, ...paginationLayoutConfig }} onChange={handleTableChanges} rowKey="id">
      <Table.Column title={formatMessage({ id: 'model.id' })} dataIndex="id" width={80} {...getSortColumnProps('bills.id')} />
      <Table.Column title={formatMessage({ id: 'model.company' })} dataIndex="company" {...getFilterColumnProps('company', CompanyMultiSelect)} />
      <Table.Column title={formatMessage({ id: 'model.attribute.reference' })} dataIndex="reference" key="reference" />
      <Table.Column title={formatMessage({ id: 'model.attribute.totalAmount' })} dataIndex="totalAmount" key="totalAmount" />
      <Table.Column
        title={formatMessage({ id: 'model.attribute.createdAt' })}
        dataIndex="createdAt"
        {...getSortColumnProps('bills.created_at')}
        render={(text) => renderDateTimeCell(text, intl)}
      />
      <Table.Column
        title={formatMessage({ id: 'model.attribute.paidOn' })}
        dataIndex="paidOn"
        {...getSortColumnProps('paid_on')}
        render={(text) => renderDateTimeCell(text, intl)}
      />
      <Table.Column title={formatMessage({ id: 'actions' })} key="actions" render={renderActions} width="200px" align="center" />
    </Table>
  );
};
