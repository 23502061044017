import React, { ReactNode } from 'react';
import { TableColumnProps, TablePaginationConfig, theme } from 'antd';
import { ColumnFilterItem, FilterDropdownProps, FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { IntlShape } from 'react-intl';
import { Index } from '../models';
import { QueryParams, SortOrder, useQueryParams } from '../models/queryParams';
import { NoDataText } from '../components/NoDataText';
import { Certification } from '../models/certification';
import { CheckOutlined, StopOutlined } from '@ant-design/icons';
import { ResultStatus } from '../models/result';

export type IndexProps<T> = {
  index: Index<T>;
};

export const useDynamicTable = <T>(queryParams?: QueryParams, setQueryParams?: (queryParams: QueryParams) => void) => {
  const [searchQueryParams, setSearchQueryParams] = useQueryParams();

  queryParams = queryParams || searchQueryParams;
  setQueryParams = setQueryParams || setSearchQueryParams;

  return {
    handleTableChanges: (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue>,
      sorter: SorterResult<T> | SorterResult<T>[],
      extra: TableCurrentDataSource<T>
    ) => {
      const updateParams = (updatedParam: Partial<QueryParams>) => {
        setQueryParams({ ...queryParams, ...updatedParam });
      };

      switch (extra.action) {
        case 'paginate':
          updateParams({ page: pagination.current });
          break;
        case 'sort':
          if (Array.isArray(sorter)) {
            throw Error('Sorting by multiple fields not yet supported.');
          }
          const parsedSorter = sorter as SorterResult<T>;
          updateParams({ sorting: { field: parsedSorter.columnKey?.toString(), order: SortOrder[parsedSorter.order] } });
          break;
        default:
          const filterParams = Object.entries(filters).reduce((previous, [key, value]) => ({ ...previous, [key]: value?.join(',') }), {});
          updateParams({ filters: { ...queryParams.filters, ...filterParams } });
          break;
      }
    },
    getFilterColumnProps: (key: string, filter: ColumnFilterItem[] | ((props: FilterDropdownProps) => ReactNode)): TableColumnProps<T> => ({
      ...(Array.isArray(filter) ? { filters: filter } : { filterDropdown: filter }),
      filteredValue: queryParams.filters?.[key]?.split(','),
      key
    }),
    getSortColumnProps: (key: string): TableColumnProps<T> => {
      const order = queryParams.sorting?.order === SortOrder.ascend ? 'ascend' : 'descend';
      const sortOrder = queryParams.sorting?.field === key ? order : null;
      return {
        sortOrder,
        sorter: true,
        key
      };
    }
  };
};

export const renderDateTimeCell = (text: string, intl: IntlShape) => {
  if (!text) {
    return React.createElement(NoDataText);
  }
  return `${intl.formatDate(text)} ${intl.formatTime(text)}`;
};

export const renderFullNameCell = (user: { forename: string; surname: string }) => {
  return `${user.forename} ${user.surname}`;
};

export const renderActiveCell = (_text: string, record: { active: boolean }) => {
  return React.createElement(record.active ? CheckOutlined : StopOutlined);
};

export const renderStatusCell = (formatMessage: IntlShape['formatMessage']) => (value: ResultStatus) => formatMessage({ id: `model.attribute.status.${ResultStatus[value]}` });

export const getCancelledRowComponentProps = <T extends { cancelledAt?: string }>(intl: IntlShape) => {
  const { token } = theme.useToken();
  return (record: T) =>
    record.cancelledAt && {
      title: intl.formatMessage({ id: 'model.attribute.cancelledAt' }, { date: intl.formatDate(record.cancelledAt) }),
      style: {
        textDecoration: 'line-through',
        backgroundColor: token.colorBgContainerDisabled
      }
    };
};

export const getActiveFilters = ({ formatMessage }: IntlShape): ColumnFilterItem[] => [
  { text: formatMessage({ id: 'model.attribute.active' }), value: true },
  { text: formatMessage({ id: 'model.attribute.inactive' }), value: false }
];

export const getYesNoFilters = ({ formatMessage }: IntlShape): ColumnFilterItem[] => [
  { text: formatMessage({ id: 'view.yes' }), value: true },
  { text: formatMessage({ id: 'view.no' }), value: false }
];

export const getCertificationFilters = (certifications: Certification[]) => certifications.map((c) => ({ text: c.title, value: c.rootId }));

export const getFiltersFromEnum = (e: Object, translationKey: string, { formatMessage }: IntlShape): ColumnFilterItem[] =>
  Object.entries(e).map(([key, value]) => ({ text: formatMessage({ id: `model.attribute.${translationKey}.${value}` }), value: key }));
