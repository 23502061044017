import { Participant, initialParticipant } from './participant';
import { Certification } from './certification';

export enum ResultStatus {
  invited = 'invited',
  invited_and_expired = 'invitedAndExpired',
  started = 'started',
  started_and_expired = 'startedAndExpired',
  passed = 'passed',
  passed_manually = 'passedManually',
  failed = 'failed',
  failed_manually = 'failedManually'
}

export const isSubmitted = (status: ResultStatus) => [ResultStatus.passed, ResultStatus.failed].includes(status);
export const isExpired = (status: ResultStatus) => [ResultStatus.invited_and_expired, ResultStatus.started_and_expired].includes(status);
export const isOpen = (status: ResultStatus) => [ResultStatus.started, ResultStatus.invited].includes(status);

export interface Result {
  id: number;
  validUntil: string;
  participant: Participant;
  certification: Certification;
  status: ResultStatus;
  expirationDate: string;
  response?: {
    id: number;
    submittedAt?: string;
    credential?: string;
    certificationPdfUrl?: string;
  };
}

export const isManualResult = (result: Result) => !result.validUntil;

export const initialResult: Result = {
  id: undefined,
  validUntil: undefined,
  participant: initialParticipant,
  certification: undefined,
  status: undefined,
  expirationDate: undefined
};
export interface ResultDraft {
  participantId: number;
  certificationId: number;
  submittedAt: string;
  achievedScore: number;
  reinvite: boolean;
}
