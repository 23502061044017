import React from 'react';
import styled from '@emotion/styled';
import { Button, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { FormattedMessage } from 'react-intl';
import { SelectorProps } from '../services/selectorService';

const StyledForm = styled(Form)`
  padding: 1rem;
  min-width: 30rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

type FilterForm<T extends {id: number}> = {
  filters: T[];
};

type Props<T extends { id: number }> = {
  Selector: React.FunctionComponent<SelectorProps<T>>;
  filterDropdownProps: FilterDropdownProps;
  filterAttributeName?: keyof T;
};

export function MultiSelectFilter<T extends { id: number }>({ Selector, filterDropdownProps, filterAttributeName = 'id' }: Props<T>) {
  const [form] = useForm<FilterForm<T>>();

  React.useEffect(() => {    
    const filters = filterDropdownProps.selectedKeys.map((s) => ({ id: parseInt(s.toString()) }))
    // TODO find possible typing
    form.setFieldsValue({ filters: filters as any });
  }, [filterDropdownProps.selectedKeys, form]);

  const handleSubmit = ({ filters }: FilterForm<T>) => {
    filterDropdownProps.setSelectedKeys(filters.map((f) => f[filterAttributeName.toString()]));
    filterDropdownProps.confirm();
  };

  const handleReset = () => {
    filterDropdownProps.clearFilters();
    form.resetFields();
  };

  return (
    <StyledForm form={form} onFinish={handleSubmit}>
      <Form.Item name="filters" style={{ flex: 1 }}>
        <Selector selectProps={{ mode: 'multiple', allowClear: false }} />
      </Form.Item>
      <Button type="link" size="small" onClick={handleReset}>
        <FormattedMessage id="action.reset" />
      </Button>
      <Button type="primary" onClick={form.submit}>
        <FormattedMessage id="action.ok" />
      </Button>
    </StyledForm>
  );
}
