import * as React from 'react';
import { Table, Space } from 'antd';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Participant } from '../../models/participant';
import { Gender } from '../../models/gender';
import { NoDataText } from '../NoDataText';
import { ParticipantForm } from './ParticipantForm';
import { getActiveFilters, useDynamicTable, IndexProps, renderActiveCell } from '../../services/indexService';
import { paginationLayoutConfig } from '../../config/pagination';
import { ColumnFilterItem, FilterDropdownProps } from 'antd/lib/table/interface';
import { CompanySelector } from '../selectors/CompanySelector';
import { MultiSelectFilter } from '../MultiSelectFilter';
import { Company } from '../../models/company';
import { DebounceSearch } from '../DebounceSearch';
import { useQueryParams } from '../../models/queryParams';

const StyledSearchField = styled(DebounceSearch)`
  width: 20rem;
  margin-bottom: 1rem;
`;
interface Props extends IndexProps<Participant> {
  genders: Gender[];
  updateParticipant: (participant: Participant) => void;
}

export const ParticipantIndex: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const { handleTableChanges, getFilterColumnProps, getSortColumnProps } = useDynamicTable();
  const [queryParams, setQueryParams] = useQueryParams();

  const renderActions = (record: Participant) => (
    <Space.Compact>
      <ParticipantForm saveParticipant={props.updateParticipant} genders={props.genders} participants={props.index.data} participant={record} />
    </Space.Compact>
  );

  const genderFilters: ColumnFilterItem[] = props.genders.map((g) => ({ text: formatMessage({ id: `model.gender.${g.name}` }), value: g.id }));

  const renderGenderCell = (_text: string, record: Participant) => {
    return record.gender ? formatMessage({ id: `model.gender.${record.gender}` }) : <NoDataText />;
  };

  const CompanyMultiSelect = (props: FilterDropdownProps) => <MultiSelectFilter<Company> Selector={CompanySelector} filterDropdownProps={props} />;

  return (
    <>
      <StyledSearchField onDebounceSearch={(value) => setQueryParams({ ...queryParams, search: value })} allowClear />
      <Table dataSource={props.index.data} pagination={{ ...paginationLayoutConfig, ...props.index.meta }} onChange={handleTableChanges} rowKey="id">
        <Table.Column title={formatMessage({ id: 'model.id' })} dataIndex="id" width={80} {...getSortColumnProps('participants.id')} />
        <Table.Column title={formatMessage({ id: 'model.attribute.email' })} dataIndex="email" key="email" />
        <Table.Column title={formatMessage({ id: 'model.gender' })} dataIndex="gender" render={renderGenderCell} {...getFilterColumnProps('gender', genderFilters)} />
        <Table.Column title={formatMessage({ id: 'model.attribute.forename' })} dataIndex="forename" key="forename" />
        <Table.Column title={formatMessage({ id: 'model.attribute.surname' })} dataIndex="surname" key="surname" />
        <Table.Column title={formatMessage({ id: 'model.company' })} dataIndex={['company', 'name']} {...getFilterColumnProps('company', CompanyMultiSelect)} />
        <Table.Column title={formatMessage({ id: 'model.attribute.active' })} render={renderActiveCell} {...getFilterColumnProps('active', getActiveFilters(intl))} />
        <Table.Column title={formatMessage({ id: 'actions' })} key="actions" render={renderActions} width="200px" align="center" />
      </Table>
    </>
  );
};
