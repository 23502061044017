import { Spin } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { CompanyClient } from '../../client/company';
import { useApiClient } from '../../hooks/client';
import { useIndexQuery } from '../../hooks/rootQueries';
import { Company } from '../../models/company';
import { Query } from '../../models/query';
import { QueryParams } from '../../models/queryParams';
import { resolveIdValues, resolveSelectedValue, SelectorProps } from '../../services/selectorService';
import { DebounceSelect } from './DebounceSelect';

type Props = {} & SelectorProps<Company>;

export const CompanySelector: React.FC<Props> = (props) => {
  const { formatMessage } = useIntl();  

  const initialParams: QueryParams = { page: 1 };
  const [queryParams, setQueryParams] = React.useState(initialParams);

  const client = useApiClient(CompanyClient);
  const { data: companyIndex, isLoading } = useIndexQuery(Query.COMPANIES, client, queryParams);

  const handleSearch = (value: string) => {
    setQueryParams({ ...queryParams, search: value });
  };

  const handleChange = (value: number | number[]) => { 
    props.onChange(resolveSelectedValue(value, companyIndex.data));
  };

  return (
    <DebounceSelect
      placeholder={formatMessage({ id: 'model.company' })}
      onDebounceSearch={handleSearch}
      onChange={handleChange}
      value={resolveIdValues(props.value)}
      notFoundContent={isLoading && <Spin size="small" />}
      options={companyIndex.data.map((c) => ({ label: c.name, value: c.id }))}
      {...props.selectProps}
    />
  );
};
