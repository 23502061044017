import * as React from 'react';
import { Button, Space, Table, TableColumnsType, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { ScheduledInvitation, ScheduleReason } from '../../models/scheduledInvitation';
import { paginationLayoutConfig } from '../../config/pagination';
import {
  getCertificationFilters,
  getFiltersFromEnum,
  useDynamicTable,
  IndexProps,
  renderDateTimeCell,
  renderFullNameCell,
  getCancelledRowComponentProps
} from '../../services/indexService';
import { Certification } from '../../models/certification';
import { MultiSelectFilter } from '../MultiSelectFilter';
import { ParticipantSelector } from '../selectors/ParticipantSelector';
import { StopOutlined } from '@ant-design/icons';

interface Props extends IndexProps<ScheduledInvitation> {
  certifications: Certification[];
  onCancel: (id: number) => void;
}

export const ScheduledInvitationIndex: React.FC<Props> = ({ index, certifications, onCancel }) => {
  const intl = useIntl();

  const { handleTableChanges, getSortColumnProps, getFilterColumnProps } = useDynamicTable();

  const columns: TableColumnsType<ScheduledInvitation> = [
    {
      dataIndex: 'id',
      title: intl.formatMessage({ id: 'model.id' }),
      width: 80,
      ...getSortColumnProps('scheduled_invitations.id')
    },
    {
      dataIndex: 'participant',
      title: intl.formatMessage({ id: 'model.participant' }),
      render: (value) => renderFullNameCell(value),
      ...getFilterColumnProps('participant', (props) => <MultiSelectFilter Selector={ParticipantSelector} filterDropdownProps={props} />)
    },
    {
      dataIndex: ['certification', 'title'],
      title: intl.formatMessage({ id: 'model.certification' }),
      ...getFilterColumnProps('certification', getCertificationFilters(certifications))
    },
    {
      dataIndex: 'reason',
      title: intl.formatMessage({ id: 'model.attribute.reason' }),
      ...getFilterColumnProps('reason', getFiltersFromEnum(ScheduleReason, 'reason', intl))
    },
    {
      dataIndex: 'inviteAt',
      title: intl.formatMessage({ id: 'model.attribute.inviteAt' }),
      render: (value) => renderDateTimeCell(value, intl),
      ...getSortColumnProps('invite_at')
    },
    {
      key: 'actions',
      align: 'center',
      render: (_value, record) => (
        <Space.Compact>
          {!record.cancelledAt && (
            <Tooltip title={intl.formatMessage({ id: 'action.cancelInvitation' })}>
              <Button onClick={() => onCancel(record.id)} icon={<StopOutlined />} />
            </Tooltip>
          )}
        </Space.Compact>
      )
    }
  ];

  return (
    <Table<ScheduledInvitation>
      dataSource={index.data}
      columns={columns}
      pagination={{ ...index.meta, ...paginationLayoutConfig }}
      onChange={handleTableChanges}
      onRow={getCancelledRowComponentProps(intl)}
      rowKey="id"
    />
  );
};
