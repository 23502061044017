import * as React from 'react';
import { useIntl } from 'react-intl';
import ParticipantsImporter from '../components/participant/ParticipantsImporter';
import { ParticipantIndex } from '../components/participant/ParticipantIndex';
import { StageContainer } from '../components/layout/StageContainer';
import { ParticipantForm } from '../components/participant/ParticipantForm';
import { CustomNotificationProps, useCreateMutation, useIndexQuery, useUpdateMutation } from '../hooks/rootQueries';
import { Query } from '../models/query';
import { useApiClient } from '../hooks/client';
import { ParticipantClient } from '../client/participant';
import { CompanyClient } from '../client/company';
import { GenderClient } from '../client/gender';
import { useCreateMultipleParticipantsMutation } from '../hooks/participant';
import { createInitialParams, useQueryParams } from '../models/queryParams';
import { Participant } from '../models/participant';

const ParticipantContainer: React.FC = () => {
  const intl = useIntl();

  const initialParams = createInitialParams('participants.id');
  const [queryParams] = useQueryParams(initialParams);

  const participantClient = useApiClient(ParticipantClient);
  const { data: participantIndex } = useIndexQuery(Query.PARTICIPANTS, participantClient, queryParams);

  const notificationProps: CustomNotificationProps<Participant> = { attributeKey: 'email', attributeTranslation: 'model.attribute.email' };

  const updateMutation = useUpdateMutation(Query.PARTICIPANTS, participantClient, notificationProps);
  const createMutation = useCreateMutation(Query.PARTICIPANTS, participantClient, initialParams, notificationProps);
  const createMultipleMutation = useCreateMultipleParticipantsMutation(participantClient, initialParams);

  const companyClient = useApiClient(CompanyClient);
  const { data: companyIndex } = useIndexQuery(Query.COMPANIES, companyClient, {});

  const genderClient = useApiClient(GenderClient);
  const { data: genderIndex } = useIndexQuery(Query.GENDERS, genderClient, {});

  return (
    <StageContainer
      title={intl.formatMessage({ id: 'model.participants' })}
      headerActions={
        <>
          <ParticipantsImporter companies={companyIndex.data} genders={genderIndex.data} participants={participantIndex.data} saveParticipants={createMultipleMutation.mutate} />
          <ParticipantForm genders={genderIndex.data} participants={participantIndex.data} saveParticipant={createMutation.mutate} />
        </>
      }
    >
      <ParticipantIndex index={participantIndex} genders={genderIndex.data} updateParticipant={updateMutation.mutate} />
    </StageContainer>
  );
};

export default ParticipantContainer;
