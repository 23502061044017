import { StageContainer } from '../components/layout/StageContainer';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { BillIndex } from '../components/bill/BillIndex';
import { useApiClient } from '../hooks/client';
import { BillClient } from '../client/bill';
import { useDeleteMutation, useIndexQuery, useUpdateMutation } from '../hooks/rootQueries';
import { Query } from '../models/query';
import { createInitialParams, useQueryParams } from '../models/queryParams';

const BillContainer: React.FC = () => {
  const intl = useIntl();

  const [queryParams] = useQueryParams(createInitialParams('bills.id'));

  const client = useApiClient(BillClient);
  const { data: index } = useIndexQuery(Query.BILLS, client, queryParams);
  const deleteMutation = useDeleteMutation(Query.BILLS, client);
  const updateMutation = useUpdateMutation(Query.BILLS, client);

  return (
    <StageContainer title={intl.formatMessage({ id: 'view.billing' })}>
      <BillIndex index={index} updateBill={updateMutation.mutate} deleteBill={deleteMutation.mutate} />
    </StageContainer>
  );
};

export default BillContainer;
