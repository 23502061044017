import { message } from 'antd';
import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { InvitationClient } from '../client/invitation';
import { ScheduledInvitationClient } from '../client/scheduledInvitation';
import { InvitationDraft } from '../models/invitation';
import { Query } from '../models/query';
import { QueryParams, useQueryParams } from '../models/queryParams';
import { buildQueryKeys } from './rootQueries';

export const useCreateMultipleInvitationsMutation = (apiClient: InvitationClient, queryParams: QueryParams) => {
  const queryClient = useQueryClient();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_queryParams, setQueryParams] = useQueryParams();
  const { formatMessage } = useIntl();
  return useMutation(
    (invitationRequest: { invitations: InvitationDraft[]; skipDuplicateValidation?: boolean }) =>
      apiClient.createMultiple(invitationRequest.invitations, invitationRequest.skipDuplicateValidation).then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(buildQueryKeys(Query.INVITATIONS, queryParams));
        setQueryParams(queryParams);

        message.success(formatMessage({ id: 'notification.success.created' }, { entity: `${data.data.length} ${formatMessage({ id: 'model.invitations' })}` }));
      }
    }
  );
};

export const useManuallyReinviteMutation = (apiClient: InvitationClient, queryParams: QueryParams) => {
  const queryClient = useQueryClient();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_queryParams, setQueryParams] = useQueryParams();
  const { formatMessage } = useIntl();
  return useMutation((id: number) => apiClient.reinvite(id).then(({ data }) => data), {
    onSuccess: () => {
      queryClient.invalidateQueries(buildQueryKeys(Query.INVITATIONS, queryParams));
      setQueryParams(queryParams);

      message.success(formatMessage({ id: 'notification.success.reinvited' }));
    }
  });
};

export const useCancelMutation = (apiClient: InvitationClient | ScheduledInvitationClient, query: Query, queryParams: QueryParams) => {
  const queryClient = useQueryClient();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_queryParams, setQueryParams] = useQueryParams();
  const { formatMessage } = useIntl();
  return useMutation((id: number) => apiClient.cancel(id).then(({ data }) => data), {
    onSuccess: () => {
      queryClient.invalidateQueries(buildQueryKeys(query, queryParams));
      setQueryParams(queryParams);

      message.success(formatMessage({ id: 'notification.success.cancelInvitation' }));
    }
  });
};
