import styled from '@emotion/styled';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player/youtube';
import { usePageVisibility } from '../../../services/pageVisibility';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const playerSize: { width: number; height: number } = {
  width: 840,
  height: 441
};

interface Props {
  url: string;
  playerConfig: Record<string, any>;
  playerSize?: { width: number; height: number };
  onChange?: (finished: boolean) => void;
  id?: string;
}

export const VideoFinishingInput: React.FC<Props> = (props) => {
  const isVisible = usePageVisibility();
  const [isPlaying, setIsPlaying] = React.useState(false);

  return (
    <Container id={props.id}>
      <ReactPlayer
        playing={isVisible && isPlaying}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        url={props.url}
        fallback={<FormattedMessage id="view.videoNotFound" />}
        {...playerSize}
        onEnded={() => {
          setIsPlaying(false);
          props.onChange(true);
        }}
        config={{ playerVars: props.playerConfig, embedOptions: playerSize }}
      />
    </Container>
  );
};
