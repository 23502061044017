import { StageContainer } from '../components/layout/StageContainer';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ResultIndex } from '../components/result/ResultIndex';
import { Route, Routes, useNavigate } from 'react-router';
import { ResultDisplayContainer } from './result/ResultDisplay';
import { ResultForm } from '../components/result/ResultForm';
import { useApiClient } from '../hooks/client';
import { ResultClient } from '../client/result';
import { useIndexQuery } from '../hooks/rootQueries';
import { Query } from '../models/query';
import { useCreateResultMutation, useRegenerateCertificateMutation } from '../hooks/result';
import { createInitialParams, useQueryParams } from '../models/queryParams';
import { ResultDraft } from '../models/result';
import { Modal } from 'antd';
import { AxiosError } from 'axios';

const ResultContainer: React.FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const initialParams = createInitialParams('invitations.id');
  const [queryParams] = useQueryParams(initialParams);

  const resultClient = useApiClient(ResultClient);
  const { data: index } = useIndexQuery(Query.RESULTS, resultClient, queryParams);
  const createMutation = useCreateResultMutation(resultClient);
  const regenerateCertificateMutation = useRegenerateCertificateMutation(resultClient);

  const openResult = (id: number) => {
    navigate(`${id}`);
  };

  const [tempResult, setTempResult] = React.useState<ResultDraft>();
  const [showDuplicatesDialog, setShowDuplicatesDialog] = React.useState(false);
  const toggleDuplicatesDialog = () => setShowDuplicatesDialog(!showDuplicatesDialog);

  const createResult = (result: ResultDraft, skipDuplicateValidation?: boolean) => {
    setTempResult(result);
    return createMutation.mutate(
      { result, skipDuplicateValidation },
      {
        onError: (error: AxiosError) => {
          if (error.response.status === 422) {
            setShowDuplicatesDialog(true);
          }
        },
        onSuccess: () => {
          setShowDuplicatesDialog(false);
        }
      }
    );
  };

  const Index = (
    <StageContainer title={formatMessage({ id: 'model.results' })} headerActions={<ResultForm addResult={createResult} />}>
      <ResultIndex index={index} openResult={openResult} onRegenerateCertificate={regenerateCertificateMutation.mutate} />
      <Modal
        open={showDuplicatesDialog}
        title={formatMessage({ id: 'view.shouldOverwriteWithResultDialog.title' })}
        okText={formatMessage({ id: 'action.overwrite' })}
        onOk={() => {
          createResult(tempResult, true);
        }}
        onCancel={toggleDuplicatesDialog}
      >
        <FormattedMessage id="view.shouldOverwriteWithResultDialog.description" />
      </Modal>
    </StageContainer>
  );

  const ResultDisplay = <ResultDisplayContainer />;

  return (
    <Routes>
      <Route path="/" element={Index} />
      <Route path=":id" element={ResultDisplay} />
    </Routes>
  );
};

export default ResultContainer;
